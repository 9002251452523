import React from 'react';
// import sections
import Container from '../components/sections/Container';

const Four04 = () => {

  return (
    <Container
      title="404"
      paragraph="Page not found"
    >
    </Container>
  );
}

export default Four04;